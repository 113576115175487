/*
 * Este archivo no debería tener imports a otros types de otras carpetas.
 * Esto es para evitar el error de "import/no-cycle"
 */

import { PaletteMode } from '@mui/material';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { Action } from 'redux';

export type ThemePreference = PaletteMode | 'system';

interface PermissionsCountry {
  countryAcronym: string;
  stores: string[];
}

export interface Permission {
  permission: string;
  countries: PermissionsCountry[];
}

export enum AppPermissions {
  DASHBOARD_MISSINGS = 'DASHBOARD_MISSINGS',
  DASHBOARD_MONTH_CLOSING = 'DASHBOARD_MONTH_CLOSING',
  DASHBOARD_SALES_GCS = 'DASHBOARD_SALES_GCS',
  DASHBOARD_DAILY_SALES = 'DASHBOARD_DAILY_SALES',
  DASHBOARD_MASTER_IMPORT = 'DASHBOARD_MASTER_IMPORT',
  REPROCESS = 'REPROCESS',
  COMPANY_CODES = 'COMPANY_CODES',
  COMPANY_CODES_QUERY = 'COMPANY_CODES_QUERY',
  ACCOUNTING_PARAMETERS = 'ACCOUNTING_PARAMETERS',
  ACCOUNTING_PARAMETERS_QUERY = 'ACCOUNTING_PARAMETERS_QUERY',
  ACCOUNTING_ENTRIES_COMPLETE = 'ACCOUNTING_ENTRIES_COMPLETE',
  ACCOUNTING_ENTRIES_EXECUTION = 'ACCOUNTING_ENTRIES_EXECUTION',
  ACCOUNTING_ENTRIES_QUERY = 'ACCOUNTING_ENTRIES_QUERY',
  MONTH_CLOSING = 'MONTH_CLOSING',
  SALES_TOTALS = 'SALES_TOTALS',
  GCS = 'GCS',
  MISSING_CLOSING_DETAIL = 'MISSING_CLOSING_DETAIL',
  TENDER = 'TENDER',
  RELIEFS = 'RELIEFS',
  CANCELLATIONS = 'CANCELLATIONS',
  OAK_BROOK_SALES = 'OAK_BROOK_SALES',
}

export interface HttpRequestError {
  notified: boolean;
  code: number;
  data?: object;
  innerException?: AxiosError;
}

export interface RemoteRequest {
  loading: boolean;
  loaded: boolean;
  error: HttpRequestError | null;
  failure: boolean;
}

export interface RemoteResource<TData> extends RemoteRequest {
  data: TData;
}

export interface Division {
  id: string | null;
  label: string;
  divisionId: string;
  divisionName: string;
}

export interface Country extends Division {
  countryId: string;
  countryCode: string;
}

export interface RegionalManagement extends Country {
  regionalMgmt: string;
  regionalMgmtId: string;
}

export interface Agreement extends Country {
  agreementId: string | null;
  agreementType: string;
}

export interface Region extends RegionalManagement {
  region: string;
  regionId: string;
}

export interface Management extends Region {
  mgmtId: string;
  mgmt: string;
}

export interface Supervision extends Management {
  supervisionId: string;
  supervision: string;
}

export interface Company {
  countries: Country[];
  companyId: number;
  companyName: string;
}

export interface Stores extends Country {
  agreementId: string | null;
  agreementType: string;
  companyId: number;
  companyName: string;
  regionalMgmt: string;
  regionalMgmtId: string;
  region: string;
  regionId: string;
  mgmtId: string;
  mgmt: string;
  supervisionId: string;
  supervision: string;
  storeId: number;
  storeAcronym: string;
}

export type OpStructures = {
  divisionId: string;
  division: string;
  countryId: number;
  country: string;
  regionalMgmtId: string;
  regionalMgmt: string;
  regionId: string;
  region: string;
  mgmtId: string;
  mgmt: string;
  supervisionId: string;
  supervision: string;
  storeEntityId: number;
  storeAcronym: string;
  companyId: number;
  companyName: string;
  agreementId: string;
  agreementType: string;
}[];

export interface PosType {
  segmentId: string;
  segmentDesc: string;
}

export interface MasterData {
  divisions: string[];
  countries: Country[];
  regionalManagements: RegionalManagement[];
  regions: Region[];
  managements: Management[];
  supervisions: Supervision[];
  stores: Stores[];
  posTypes: PosType[];
  companies: Company[];
  agreements: Agreement[];
  federativeEntities: string[];
}

export interface StoreExtraInfoParameters {
  storeId: number;
  name: string;
  baseStoreId: number | undefined;
}

export interface AppParameters {
  filtersLimitMonths: number;
  dashboardTimer: number /** minutes */;
  storeExtraInfo: StoreExtraInfoParameters[];
}

export interface HttpErrorBaseAction<TAction> extends Action<TAction> {
  error: HttpRequestError;
}

export interface Discount {
  idDiscount: number;
  description: string;
  transactionType: number;
}

export interface SaleType {
  saleTypeId: string;
  saleTypeDescription: string;
}

export interface PaymentType {
  paymentTypeId: number;
  paymentType: string;
  tenderId: string;
  shortName: string;
  tenderNameBr: string;
}

export interface HourRange {
  hour: string;
}

export interface TimeBand {
  description: string;
}

export interface DateValue {
  raw: object | null | undefined;
  accept: Date | null;
}

export interface OperationType {
  id: number;
  name: string;
}

export type ReportFilterGroupBy =
  | 'countryAcronym'
  | 'storeAcronym'
  | 'businessDate'
  | 'accountingDate'
  | 'operationDate'
  | 'posNum'
  | 'saleType'
  | 'timeRange'
  | 'timeBand'
  | 'posType'
  | 'regionalMgmtId'
  | 'regionId'
  | 'mgmtId'
  | 'supervisionId'
  | 'agreementId'
  | 'companyId'
  | 'plu'
  | 'transactionType'
  | 'podPod';

export type ReportFilterTenderGroupBy =
  | 'countryAcronym'
  | 'storeAcronym'
  | 'businessDate'
  | 'accountingDate'
  | 'operationDate'
  | 'posNum'
  | 'saleType'
  | 'timeRange'
  | 'timeBand'
  | 'posType'
  | 'tenderName'
  | 'operationTypeName';

export enum MissingCauses {
  // General Errors
  CLOSING_NOT_INFORMED = 'CLOSING_NOT_INFORMED',
  DIFFERENCE_BETWEEN_CLOSING_AND_DETAIL = 'DIFFERENCE_BETWEEN_CLOSING_AND_DETAIL',

  // Sales Errors
  MISSING_PAYMENT_TYPE = 'MISSING_PAYMENT_TYPE',
  MISSING_SALE_TYPE = 'MISSING_SALE_TYPE',
  NO_ITEMS_IN_TICKET = 'NO_ITEMS_IN_TICKET',
  NO_TENDERS_IN_TICKET = 'NO_TENDERS_IN_TICKET',
  NO_TAXES_IN_TICKET = 'NO_TAXES_IN_TICKET',
  INVALID_FISCAL_DOC = 'INVALID_FISCAL_DOC',
  ZERO_AMOUNT = 'ZERO_AMOUNT',
  MORE_THAN_ONE_DAY_BETWEEN_OPERATION_AND_BUSINESS_DATES = 'MORE_THAN_ONE_DAY_BETWEEN_OPERATION_AND_BUSINESS_DATES',
  PLU_MORE_THAN_1M_AND_UNIT_PRICE_NO_ZERO = 'PLU_>_1M_AND_UNIT_PRICE_>_0',
  OPERATION_DATE_BEFORE_BUSINESS_DATE = 'OPERATION_DATE_BEFORE_BUSINESS_DATE',
  PLU_MORE_THAN_1M = 'PLU_>_1M',
  ITEM_ZERO_AMOUNT = 'ITEM_ZERO_AMOUNT',
  ACCOUNTING_STORE_ID_NOT_FOUND_IN_FISCAL_MASTER = 'ACCOUNTING_STORE_ID_NOT_FOUND_IN_FISCAL_MASTER',
  DUPLICATE_DISCOUNT = 'DUPLICATE_DISCOUNT',
  DUPLICATE_CUPONS = 'DUPLICATE_CUPONS',
  DIFFERENCE_BETWEEN_SALE_AND_ITEM = 'DIFFERENCE_BETWEEN_SALE_AND_ITEM',
  DIFFERENCE_BETWEEN_SALE_AND_TENDER = 'DIFFERENCE_BETWEEN_SALE_AND_TENDER',
  MISSING_POS_IN_CLOSING_FILE = 'MISSING_POS_IN_CLOSING_FILE',
  MISSING_CNPJ = 'MISSING_CNPJ',
  MISSING_TAXES = 'MISSING_TAXES',
  DUPLICATE_INVOICES = 'DUPLICATE_INVOICES',
  MISSING_EXTRA_CASH = 'MISSING_EXTRA_CASH',
  FIELD_LENGTH_EXCEEDED = 'FIELD_LENGTH_EXCEEDED',

  // Collection Sale Errors
  MISSING_PAYMENT_TYPE_ENVELOPE = 'MISSING_PAYMENT_TYPE_ENVELOPE',
  MISSING_POS_ENVELOPE = 'MISSING_POS_ENVELOPE',
  MISSING_ACCOUNT_DATA = 'MISSING_ACCOUNT_DATA',

  // Grouped Errors
  GROUPED_TOTALS_DIFFERENCE = 'GROUPED_TOTALS_DIFFERENCE',
  GROUPED_ECONOMIC_DIFFERENCE = 'GROUPED_ECONOMIC_DIFFERENCE',
  GROUPED_WITHOUT_COLLECTIONS = 'GROUPED_WITHOUT_COLLECTIONS',
  GROUPED_WITHOUT_SALES = 'GROUPED_WITHOUT_SALES',
  GROUPED_GENERATION_FAIL = 'GROUPED_GENERATION_FAIL',
}

export type ReportReliefsGroupBy = 'countryAcronym' | 'storeAcronym' | 'businessDate';
export type ReportCancellationsGroupBy = 'countryAcronym' | 'storeAcronym' | 'accountingDate';
export type ReportThirdPartySalesByItemsGroupBy =
  | 'countryAcronym'
  | 'storeAcronym'
  | 'accountingDate'
  | 'plu';

export type DiscountsJoin = 'sale' | 'item';

export type PodOptionType = 'MOTHER' | 'CNPJ';

export interface ReportFilter {
  country: Country | null;
  countryCode: string;
  stores: Stores[];
  segments: PosType[];
  pos: string[];
  salesTypes: SaleType[];
  paymentTypes: PaymentType[];
  hourRange: HourRange[];
  timeBand: string[];
  startDate: DateValue;
  endDate: DateValue;
  startBusinessDate: DateValue;
  endBusinessDate: DateValue;
  startOperationDate: DateValue;
  endOperationDate: DateValue;
  startAccountingDate: DateValue;
  endAccountingDate: DateValue;
  groupBy: ReportFilterGroupBy[];
  tenderGroupBy: ReportFilterTenderGroupBy[];
  reliefsGroupBy: ReportReliefsGroupBy[];
  cancellationsGroupBy: ReportCancellationsGroupBy[];
  salesProductGroupBy: ReportFilterGroupBy[];
  thirdPartySalesByItemsGroupBy: ReportThirdPartySalesByItemsGroupBy[];
  discountsJoin: DiscountsJoin[];
  operationType: OperationType[];
  plu: number[];
  saleDiscount: Discount[];
  itemDiscount: Discount[];
  differenceType: string;
  pod: PodOptionType[];
  accountingEntriesFields: boolean;
}

export interface OpStructuresFilter {
  agreement: Agreement[];
  regionalMgmt: RegionalManagement[];
  region: Region[];
  management: Management[];
  supervision: Supervision[];
  company: Company[];
}

export interface ClosingStatusFilter {
  fromDt: string;
  toDt: string;
}

export interface FindClosingStatusRequest {
  countryAcronym: string;
  businessDateRange: string;
}

export interface FindClosingStatusTotalRequest extends FindClosingStatusRequest {
  storeAcronym: string | undefined;
}

export type FindClosingStatusResponse = {
  country: string;
  from_dt: string;
  to_dt: string;
  close_ok: number;
  close_ok_percent: number;
  close_error: number;
  close_error_percent: number;
}[];

export interface ClosingStatus {
  country: string;
  fromDt: Date;
  toDt: Date;
  closeError: number;
  closeErrorPercent: number;
  closeOk: number;
  closeOkPercent: number;
}

export interface Sale {
  country: string;
  store: string;
  businessDate: Date;
  businessWeekDay: Date;
  accountingDate: Date;
  accountingWeekDay: Date;
  operationDate: Date;
  operationWeekDay: Date;
  saleTypeWh?: number;
  posType?: string;
  posNum?: number;
  timeBand?: string;
  dayPart?: string;
  regionalMgmtId: string;
  regionId: string;
  mgmtId: string;
  supervisionId: string;
  agreementId: string;
  companyId: string;
  netSalePos: number;
  tax1Pos: number;
  tax2Pos: number;
  tax3Pos: number;
  grossSalePos: number;
  netSaleFinalPos: number;
  netSaleThirdParty: number;
  tax1ThirdParty: number;
  tax2ThirdParty: number;
  tax3ThirdParty: number;
  grossSaleThirdParty: number;
  netSaleNp: number;
  tax1Np: number;
  tax2Np: number;
  tax3Np: number;
  grossSaleNp: number;
  netSaleFinalNp: number;
  netSalePnp: number;
  tax1Pnp: number;
  tax2Pnp: number;
  tax3Pnp: number;
  grossSalePnp: number;
  netSaleFinalPnp: number;
  netSaleP: number;
  tax1P: number;
  tax2P: number;
  tax3P: number;
  grossSaleP: number;
  netSaleFinalP: number;
  gcs: number;
  gcsAvg: number;
  storeId?: number;
  accountingStoreId?: number;
  discountSalePos?: number;
  caixaMapSales?: number;
  aliquot0Sales?: number;
  loyalty?: number;
  icms?: number;
  pis?: number;
  cofins?: number;
  brindesSalesHmb?: number;
  icmsBrindesHmb?: number;
  pisBrindesHmb?: number;
  cofinsBrindesHmb?: number;
  deliveryRate?: number;
  icmsBrindesT?: number;
  pisBrindesT?: number;
  cofinsBrindesT?: number;
  brindesSalesV?: number;
  icmsBrindesV?: number;
  pisBrindesV?: number;
  cofinsBrindesV?: number;
}

export interface GcsBase {
  businessDate: Date;
  accountingDate: Date;
  operationDate: Date;
  country: string;
  store: string;
  storeId: number;
  countryId: string;
  posNum: number;
  posType: string;
  transactionType: number;
  endOfSaleTimestamp: Date;
  dayPart: string;
  receiptNum: number;
  invoiceType: string;
  invoiceKind: string;
  fiscalPosCode: string;
  saleReceipt: number;
  receiptClass: number;
  fiscalName1?: string;
  fiscalName2?: string;
  fiscalAddress1?: string;
  fiscalAddress2?: string;
  transactionAmt: number;
  taxAmt: number;
  netAmt: number;
  specialSaleOrderId?: string;
  specialSaleType: string;
  specialSaleMethod?: string;
  operatorId?: number;
  managerId?: number;
  saleKey?: string;
  plName?: string;
}

export interface Gcs extends GcsBase {
  accountingStoreId?: number;
  fiscalReceiptNum: number;
  fiscalRegisterId?: string;
  saleDiscountId?: number;
  saleDiscountDescription?: string;
  saleDiscountRate?: number;
  saleDiscountAmount?: number;
  discountOnTotalAmt?: number;
  clientMcId?: string;
  program?: string;
  digitalChannel?: number;
}

export interface GcsByPaymentTypes extends GcsBase {
  id: number;
  operationDate: Date;
  saleDescription: string;
  seqNumber: number;
  beginOfSaleTimestamp: Date;
  specialSaleType: string;
  tenderName: string;
  tenderType: number;
  amount: number;
  operationTypeName: string;
  fiscalReceiptNum: number;
  tipAmount?: number;
  idOperation?: string;
  numCoupon?: string;
  idPosnet?: string;
  posnet?: string;
  numCard?: string;
}

export interface IbGroups {
  countryId: string;
  groupId: string;
  groupName: string;
}
export interface ReprocessParameters {
  statuses: string[];
  types: string[];
  ibGroups: IbGroups[];
}

export interface ReprocessFilter {
  countryCode: string;
  storeAcronym: string;
  businessDate: DateValue;
  reprocessType: string;
  status: string;
  federativeEntity: string;
}

export interface Reprocess {
  country: string;
  store: string;
  businessDate: Date;
  type: string;
  justification: string;
  status: string;
  user: string;
  executionDate: Date;
  creationDate: Date;
  federativeEntity: string;
}

export interface ValidationError {
  field?: string;
  code: string;
}

export interface CreateReprocessForm {
  country: string;
  store?: string;
  stores?: Stores[];
  groupIds?: IbGroups[];
  businessDate?: Date | null;
  businessDateFrom?: Date | null;
  businessDateTo?: Date | null;
  type: string;
  justification: string;
  federativeEntity?: string;
}

export enum ReprocessType {
  sales = 'REPROCESS_SALES',
  missingPaymentType = 'REPROCESS_MISSING_PAYMENT_TYPE',
  missingSaleType = 'REPROCESS_MISSING_SALE_TYPE',
  grossIncome = 'REPROCESS_GROSS_INCOME',
  collections = 'REPROCESS_COLLECTIONS',
  noProduct = 'REPROCESS_NO_PRODUCT',
  cashDifferencesArcs = 'REPROCESS_CASH_DIFFERENCES_ARCS',
  salesArcs = 'REPROCESS_SALES_ARCS',
  store = 'REPROCESS_STORE',
  economicAccounting = 'REPROCESS_ECONOMIC_ACCOUNTING',
  missingCnpj = 'REPROCESS_MISSING_CNPJ',
  missingExtraCash = 'REPROCESS_MISSING_EXTRA_CASH',
  taxError = 'REPROCESS_TAX_ERROR',
  taxesBr = 'REPROCESS_TAXES_BR',
}

export interface MissingClosingDetailFilter {
  country: string;
  stores: string[];
  from: DateValue;
  to: DateValue;
}

export interface Closing {
  country: string;
  store: string;
  date: Date;
  federativeEntity: string | null;
  franchiseType: string | null;
  cause: string[];
}

// TODO: dates puede contener undefined y date. cambiar el any.
export interface ClosingDetailGroup {
  country: string;
  store: string;
  federativeEntity: string | null;
  franchiseType: string | null;
  dates: any[];
  cause: string;
  quantity: number;
}

export enum ClosingViewMode {
  CONSOLIDATED = 'consolidated',
  SALES = 'sales',
  COLLECTIONS = 'collections',
}

export enum ClosingFranchiseType {
  Y = 'Y',
  N = 'N',
}

export enum PendinInterfacesViewMode {
  CONSOLIDATED = 'consolidated',
  SALES = 'sales',
  COLLECTIONS = 'collections',
}

export interface ClosingState {
  sales: RemoteResource<Closing[]>;
  collections: RemoteResource<Closing[]>;
  current: ClosingDetailGroup[];
}

export interface MissingClosingTotal {
  filteredStores: Stores[];
  missingClosingTotal: RemoteResource<number | null>;
}

export interface DailySale {
  country: string;
  gcs: number;
  posNetSale: number;
  delayedStores: number;
}

export interface DelayedStores {
  country: string;
  store: string;
  lastSale: Date;
}

export interface DashboardAutoUpdate {
  time: number;
  lastUpdate: Date;
}

export interface ClosingStatusParameters {
  yesterdayFrom: Date;
  yesterdayTo: Date;
  currentMonthFrom: Date;
  currentMonthTo: Date;
  lastMonthFrom: Date;
  lastMonthTo: Date;
  countries: string[];
}

export type DashboardRequests =
  | 'SALES'
  | 'COLLECTIONS'
  | 'CLOSED_MONTHS'
  | 'MONTHLY_SALES'
  | 'REGISTERED_STORES'
  | 'DAILY_SALES'
  | 'DELAYED_STORES';

export interface ValidationErrorCause {
  errorType?: string;
  code?: number;
  posNumber?: number;
  sequence?: number;
  elementType?: number;
  errorDetails?: Record<string, string | number>;
}

export interface DifferencesCause {
  posNum: number;
  sequence: number;
  closeAmt?: number;
  closeGcQty?: number;
  detailAmt: number;
  detailGcQty: number;
}

export interface MissingClosingCauses {
  country: string;
  store: string;
  date: Date;
  cause: MissingCauses;
}

export interface MissingClosingSalesCauses extends MissingClosingCauses {
  details: DifferencesCause[] | ValidationErrorCause[];
}

export interface CollectionsDifferencesCause {
  transferType: string;
  sequence: number;
  closeTransferQty: number;
  detailTransferQty: number;
}

export interface MissingClosingCollectionsCauses extends MissingClosingCauses {
  details: CollectionsDifferencesCause[] | ValidationErrorCause[];
}

export interface MissingClosingCausesData {
  salesCauses: RemoteResource<MissingClosingSalesCauses | undefined>;
  collectionsCauses: RemoteResource<MissingClosingCollectionsCauses | undefined>;
}

export interface CausesFilter {
  country: string;
  store: string;
  date: DateValue;
  cause: string;
}

export interface CompanyCodesFilter {
  countryId: string;
}

export interface CompanyCode {
  countryId: string;
  name: string;
  codeWh: number;
  codeEbs?: string;
  ledger?: string;
  currency?: string;
  isAccounted?: boolean;
  companyOakId?: number;
  classCode?: number;
}

export type EditCompanyCodeForm = CompanyCode;

export interface AccountingDetailParameter {
  journalSubtype: string;
  account: string;
  subAccount: string;
}

export interface AccountingParameter {
  countryId: string;
  journalType: string;
  source: string;
  category?: string;
  categoryAlias?: string;
  frequency?: string;
  details: AccountingDetailParameter[];
}

export interface EditAccountingParameterForm {
  countryId: string;
  journalType: string;
  frequency?: string;
  details: {
    journalSubtype: string;
    account?: string;
    subAccount?: string;
  }[];
}

export enum AccountingEntryStatus {
  vigente = 'VIGENTE',
  anulado = 'ANULADO',
  processing = 'PROCESSING',
  error = 'ERROR',
}
export interface AccountingEntriesFilter {
  country: Country | null;
  accountingMonth: DateValue;
  journalType: string | null;
  status: AccountingEntryStatus | null;
}

export interface AccountingEntry {
  id: number;
  countryId: string;
  accountingMonth: Date;
  journalGroup: string;
  journalType: string;
  status: AccountingEntryStatus;
  createdDate: Date;
}

export interface CreateAccountingEntriesForm {
  countryId: string;
  accountingMonth: Date | null;
  journalGroup: string;
}

export interface DeleteAccountingEntriesForm {
  countryId: string;
  accountingMonth: Date;
  journalGroup: string;
  comments: string;
}

export enum MonthClosingStatus {
  active = 'ACTIVE',
  annuled = 'ANNULED',
}

export interface OakBrookFilter {
  country: Country | null;
  month: DateValue;
}
export interface OakBrook {
  classCode: string;
  countryId: string;
  oakStoreId: string;
  companyOakId: string;
  date: Date;
  sales: number;
  gcs: number;
}

export interface MonthClosing {
  country: string;
  accountingMonth: Date;
  status: MonthClosingStatus;
  justification: string;
  createdBy: string;
  creationDate: Date;
  updatedBy: string;
  updateDate: Date | null;
}

export interface MonthClosingFilter {
  country: string;
}

export interface CloseMonthForm {
  country: string;
  accountingMonth: Date;
  createdBy: string;
}

export interface AnnulMonthClosingForm {
  country: string;
  accountingMonth: Date;
  updatedBy: string;
  justification: string;
}

export type GroupBy<TValue> = Record<string, TValue[]>;

export interface TenderReport {
  country: string;
  store: string | undefined;
  businessDate: Date | undefined;
  operationDate: Date | undefined;
  accountingDate: Date | undefined;
  saleTypeWh: number | undefined;
  posType: string | undefined;
  posNum: number | undefined;
  dayPart: string | undefined;
  timeBand: string | undefined;
  paymentTypeId: number | undefined;
  operationTypeName: string | undefined;
  tenderName: string | undefined;
  tenderDescription: string | undefined;
  totalGrossSales: number;
  totalNetSales: number;
  extraCash: number;
  promotion: number;
  saleOriginalForeignCurrency: number;
  averagePrice: number;
}

export interface GrossSale {
  country: string;
  store?: string;
  businessDate?: Date;
  grossSale: number;
}

export interface Relief {
  country: string;
  store: string | undefined;
  businessDate: Date;
  quantity: number;
  amount: number;
  grossSale: number;
}

export interface Cancellation {
  country: string;
  store: string;
  accountingDate: Date;
  operation: string;
  quantity: number;
  amount: number;
  grossSale: number;
  percent: string;
}

export interface SalesByProduct {
  store: string | undefined;
  accountingDate: Date | undefined;
  businessDate: Date | undefined;
  operationDate: Date | undefined;
  posType: string | undefined;
  saleTypeWh: number | undefined;
  posNum: number | undefined;
  dayPart: string | undefined;
  timeBand: string | undefined;
  plu: number | undefined;
  quantitySold: number | undefined;
  quantitySoldLocal: number | undefined;
  quantitySoldDelivery: number | undefined;
  quantityWaste: number | undefined;
  quantityPromo: number | undefined;
  quantityMeal: number | undefined;
  netSalePos: number | undefined;
  grossSalePos: number | undefined;
  netSaleFinalPos: number | undefined;
  netSaleThirdParty: number | undefined;
  grossSaleThirdParty: number | undefined;
  netSaleNp: number | undefined;
  grossSaleNp: number | undefined;
  netSaleFinalNp: number | undefined;
  netSalePnp: number | undefined;
  grossSalePnp: number | undefined;
  netSaleFinalPnp: number | undefined;
  netSaleP: number | undefined;
  grossSaleP: number | undefined;
  netSaleFinalP: number | undefined;
}

export interface GCsByProduct {
  store: string | undefined;
  businessDate: Date | undefined;
  operationDate: Date | undefined;
  accountingDate: Date | undefined;
  posNum: number | undefined;
  transactionType: number | undefined;
  saleTypeWh: number | undefined;
  posType: string | undefined;
  fiscalReceiptNum: number | undefined;
  saleDescription: string | undefined;
  dayPart: string | undefined;
  plu: number | undefined;
  shortName: string | undefined;
  itemsQty: number | undefined;
  unitPriceAmt: number | undefined;
  itemStatusName: string | undefined;
  nonProductPrice: number | undefined;
  itemTotalAmt: number | undefined;
  taxAmt: number | undefined;
  taxNpAmt: number | undefined;
  thirdpartyMenuItem: boolean | undefined;
  ventaNpNetaIva: number | undefined;
  seqNumber: number | undefined;
  transactionAmt: number | undefined;
  discountOnTotalAmt?: number;
  clientMcId?: string;
  program?: string;
  digitalChannel?: number;
  saleDiscountId?: number;
  saleDiscountDescription?: string;
  saleDiscountRate?: number;
  saleDiscountAmount?: number;
  itemDiscountId?: number;
  itemDiscountDescription?: string;
  itemDiscountRate?: number;
  itemDiscountAmount?: number;
  beginOfSaleTimestamp: Date;
  endOfSaleTimestamp: Date;
}

export type Translation = typeof t | (() => '');

export interface AppNotification {
  variant: 'error' | 'success' | 'warning' | 'info';
  key: string;
  message: (t: Translation) => string;
  duration?: number;
}

export interface ThirdPartySalesByItems {
  store?: string;
  dateFrom: Date;
  dateTo: Date;
  accountingDate?: Date;
  plu?: number;
  shortName?: string;
  quantitySold: number;
  quantityPromo: number;
  quantityMeal: number;
  quantityWaste: number;
  grossSaleAd: number;
  calculatedNetSale: number;
  netSaleAd: number;
  saleMixPercentage: number;
}

export interface DailySalesData {
  country: string;
  gcs: number;
  pos_net_sale: number;
}

export interface MastersStatus {
  importDate: Date;
  status: string;
}

export interface MasterStatusDetail {
  masterType: string;
  creationDate: Date | undefined;
  error?: string;
  registerQty: number;
  status: string;
}

export interface MastersImport {
  importDate?: Date;
  type: string;
  country: string;
  description: string;
  quantity: number;
  state: string;
}

export interface PettyCashDifference {
  country: string;
  store: string;
  transferId: number;
  transferTypeId: string;
  businessDate: Date;
  operationDate: Date;
  transferTime?: string;
  posNum: number;
  shiftNum: number;
  userId: number;
  username: string;
  employeeId: number;
  employeeName: string;
  totalAmt: number;
}

export interface PettyCashDifferenceByPaymentType extends PettyCashDifference {
  tenderName: string;
  amount: number;
  foreignAmount: number;
  fcRate: number;
}

export interface SalesJournalBase {
  ivaPercentage: number;
  iva: number;
  netSale: number;
  grossSale: number;
}

export interface SalesJournalGcs extends SalesJournalBase {
  fiscalPosCode: string;
  from: number;
  to: number;
  posNum: number;
}

export interface SalesJournalNcs extends SalesJournalBase {
  invoiceType: string;
  invoiceKind: string;
  fiscalPosCode: string;
  fiscalReceiptNum: number;
  fiscalName: string;
  fiscalRegisterId: string;
}
export type SalesJournalFcs = SalesJournalNcs;

export type SalesJournalThirdParty = SalesJournalBase;

export interface SalesJournal {
  gcs: RemoteResource<SalesJournalGcs[]>;
  ncs: RemoteResource<SalesJournalNcs[]>;
  fcs: RemoteResource<SalesJournalFcs[]>;
  thirdparty: RemoteResource<SalesJournalThirdParty[]>;
}

export interface MastersImportDetailFilter {
  countries: Country[];
  startImportDate: DateValue;
  endImportDate: DateValue;
  state: string[];
}

export interface ClosingFilter {
  countries: Country[];
  stores: Stores[];
  startBusinessDate: DateValue;
  endBusinessDate: DateValue;
  viewMode: ClosingViewMode;
  federativeEntities: string[];
  franchiseTypes: string[];
  causeTypes: MissingCauses[];
}

export interface PendingInterfaces {
  country: string;
  store: string;
  businessDate: Date;
  storeId?: number;
  countryId?: string;
  dataEntry?: string;
  workingDay?: boolean;
  salesClose?: string;
  salesCause?: string;
  salesDetails?: string;
  collClose?: string;
  collCause?: string;
  collDetails?: string;
  whData?: string;
  grSalesProccesed?: string;
  grSalesSent?: string;
  grStatus?: string;
  groupedTotalSales?: string;
  groupedSalesByPayment?: string;
  groupedSalesByProduct?: string;
}

export interface PendingInterfacesFilter {
  country: string;
  stores: Stores[];
  startBusinessDate: DateValue;
  endBusinessDate: DateValue;
}

export interface PendingInterfacesState {
  sales: RemoteResource<PendingInterfaces[]>;
}

export enum ClosingDashboardViewMode {
  today = 'today',
  yesteday = 'yesterday',
  currentMonth = 'currentMonth',
  lastMonth = 'lastMonth',
}
